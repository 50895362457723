import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useLocation } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';
import { TexttoImageForm } from '../sections/forms';
import { AppSaveResources } from '../sections/@dashboard/app';
import AppLoader from '../components/loader/AppLoader';
import useLoader from '../hooks/useLoader';
import remarkGfm from 'remark-gfm';
import useCurrentPlan from '../hooks/useCurrentPlan';
import { AppSubscriptionCall } from '../sections/@dashboard/app';
import useAxiosPrivate from '../hooks/useAxiosPrivate';


// ----------------------------------------------------------------------
  
  const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '5vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

  const StyledContentResult = styled('div')(({ theme }) => ({
    margin: 'auto',
    minHeight: 480,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2, 5),
    borderWidth: 2,
    borderColor:'#BFBEC0',
    borderStyle:'solid',
    marginTop:'90px',
    overflowY:'scroll',
  }));
  
  // ----------------------------------------------------------------------

export default function TexttoImage() {
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const [isLoading, setIsLoading] = useState(false);
  const { loading } = useLoader();
  const axiosPrivate = useAxiosPrivate()
  const user_id = localStorage.getItem("inktype");
  const { currentplan, setCurrentPlan } = useCurrentPlan();

  const serviceId = "image creator";
  const [result, setResult] = useState(null)
  const [description, setDescription] = useState(null)


  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);

  useEffect(() => {
    retrieveWallet()
  })

  const getData = ({result, description}) => {
    const result_f = `![React Logo](${result})`
    setResult(result_f)
    setDescription(description)
  }

  const retrieveWallet = async () => {
    try {
      const response = await axiosPrivate.get(`/profile/wallet/${user_id}`);
      setCurrentPlan(response.data[0].name)
    } catch (error) {
      if(error.response?.status == 401){
        //console.log("Error: ", error.response)
      }
    }
  };

    return (
      <>
        {!isLoading && 
          <>
            <Helmet>
              <title> Dashboard: Text to Image Generator</title>
            </Helmet>
    
            {mdUp && (
              <StyledSection>
                <Typography variant="h3" sx={{ px: 3}}>
                  Text to Image
                </Typography>
              </StyledSection>
            )}
    
            <Container>
              {currentplan === 'Free' ? (
                
                <AppSubscriptionCall />

              ) : (
                <Grid container spacing={12}>
                  <Grid item xs={12} md={4}>
                    <StyledContent>
                      <TexttoImageForm onSubmit={getData} />
                    </StyledContent>
                  </Grid>
      
                  <Grid item xs={12} md={8}>
                    <StyledContentResult>
                      { result && <div id="resultDoc">
                      <ReactMarkdown plugins={[remarkGfm]} children={result} />
                        </div> }
                      { !result && <h3>Result:</h3>}
                    </StyledContentResult>
                    
                    {result &&
                      <Grid container spacing={12} py={3}>
                        <Grid item xs={6} md={4}>
                          <Button variant="contained" onClick={() => {navigate('/dashboard/print-pdf',
                            { state: { textResult: result } }
                          )}}>Download Image</Button>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              )}
            </Container>
          </>
        }

        {isLoading &&
          <AppLoader />
        }
      </>
      
    );
  }
  