import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const POST_TITLES = [
  '',
  'Lesson Plan Generator',
  'Curriculum Generator',
  'Lesson Note Generator',
  'Generate Assessment Questions',
  'Solver',
  'Visual Teaching Aid',
  'Grammar Correction',
  'Activity Generator',
  'Audio to Text',
  'Assignment Generator',
  'Marking Scheme Generator',
  'Appraisal/Feedback Generator',
  'Module Planner',
  'Icebreaker Activity Generator',
  'Behavior Intervention Generator',
  'Vocabulary Generator',
  'Newsletter Generator',
  'Special Needs Student Intervention Generator',
  'Language Translator',
  'Practice Test Generator',
  'Worksheet Generator',
  'Comprehension Generator',
  'Quiz Generator',
  'Writing Assistant',
  'Text-to-Speech Generator',
  'Report Card Comment',
  'Rhymes Generator',
  'Jokes',
  'Riddles',
  'Project Based Learning',
  'Group Work',
  'Science Lab Project',
  'Social Emotional Learning',
  'Coach Sport Practice',
  'SDG Generator',
  'Video & Audio Assessment',
  'Power Point Slides',
];

const POST_SUBTITLES = [
  '',
  'Lesson plan made easy with AI',
  'Generate customized curriculum',
  'Generate lesson note and handout',
  'Generate assessment questions',
  'Solve maths, physics and chemistry problems',
  'Generate visual teaching aid',
  'Enhance writing with AI',
  'Generate interactive activity',
  'Transcribe audio to text',
  'Generate assignment questions',
  'Generate marking guide/scheme',
  'Generate student appraisal',
  'Generate module/unit plan',
  'Generate icebreaker activity',
  'Generate behavior intervention',
  'generate vocabulary',
  'Generate school newsletter',
  'Get intervention ideas for students with special needs',
  'Translate text to other languages',
  'Generate practice test',
  'Worksheet Generator',
  'Comprehension Generator',
  'Quiz Generator',
  'Teacher\'s Personal Writing Assistant',
  'Text-to-Speech Generator',
  'Report Card Comment Generator',
  'Song/Rhymes Generator',
  'Teacher Jokes Generator',
  'Teacher Riddles Generator',
  'Project-Based Learning (PBL) Generator',
  'Group Work Generator',
  'Science Lab Project Generator',
  'Social-Emotional Learning (SEL) Plan Generator',
  'Coach\'s Sport Practice Plan Generator',
  'SDG Generator',
  'Video and Audio Assessment Generator',
  'PowerPoint/Slide Generator'
];

const POST_LINK = [
  '',
  '/dashboard/lessonplan',
  '/dashboard/curriculum',
  '/dashboard/handout',
  '/dashboard/assessment',
  '/dashboard/solver',
  '/dashboard/texttoimage',
  '/dashboard/grammarcorrection',
  '/dashboard/activitygenerator',
  '/dashboard/transcribe',
  '/dashboard/assignmentgenerator',
  '/dashboard/markingscheme',
  '/dashboard/appraisalgenerator',
  '/dashboard/moduleplanner',
  '/dashboard/icebreakeractivity',
  '/dashboard/behaviorintervention',
  '/dashboard/vocabularygenerator',
  '/dashboard/newslettergenerator',
  '/dashboard/specialneedsgenerator',
  '/dashboard/languagetranslator',
  '/dashboard/practicetestgenerator',
  '/dashboard/worksheetgenerator',
  '/dashboard/comprehensiongenerator',
  '/dashboard/quizgenerator',
  '/dashboard/writingassistant',
  '/dashboard/texttospeech',
  '/dashboard/reportcardcomment',
  '/dashboard/rhymes',
  '/dashboard/teachersjokes',
  '/dashboard/teacherriddles',
  '/dashboard/projectbasedlearning',
  '/dashboard/groupwork',
  '/dashboard/sciencelabproject',
  '/dashboard/socialemotionallearning',
  '/dashboard/coachsportpractice',
  '/dashboard/sdggenerator',
  '/dashboard/videoaudioassessment',
  '/dashboard/powerpointslide',

];

const PAID_PACKAGES = [
  '',
  'Curriculum Generator',
  'Generate Assessment Questions',
  'Practice Test Generator',
  'Text-to-Speech Generator',
  'Science Lab Project',
  'Video & Audio Assessment',
  'Power Point Slides',
  'Project Based Learning',
  'Worksheet Generator',
  'Visual Teaching Aid',
];

const posts = [...Array(37)].map((_, index) => ({
  id: faker.datatype.uuid(),
  cover: `/assets/images/covers/cv_${index + 1}.jpg`,
  title: POST_TITLES[index + 1],
  subtitle: POST_SUBTITLES[index + 1],
  link: POST_LINK[index + 1],
  favorite: faker.datatype.number(),
  author: {
    name: faker.name.fullName(),
    avatarUrl: PAID_PACKAGES.includes(POST_TITLES[index + 1]) ? `/assets/images/avatars/pro.png` : `/assets/images/avatars/free.png`
    //avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  },
}));

export default posts;
